//Get animator divs
var artstylingIllustration_left = document.getElementById("artstyling_illustration_left");
var artstylingIllustration_right = document.getElementById("artstyling_illustration_right");
var artstylingIllustration = document.getElementById("artstyling_illustration");

artstylingClicks = 0;


function adjustartstylingillustrationPosition(newPos){
	artstylingIllustration.style.backgroundPosition = "calc(" + newPos + " * 33%) center";
}

artstylingIllustration_left.addEventListener("click", () => { 
	if(artstylingClicks > 0)
		artstylingClicks--;
	adjustartstylingillustrationPosition(artstylingClicks);
});

artstylingIllustration_right.addEventListener("click", () => {
	if(artstylingClicks < 3)
		artstylingClicks++;
	adjustartstylingillustrationPosition(artstylingClicks);
});
